import React from 'react';
import MCPageHeader from '../../../components/magicrop/MCPageHeader';
import { Helmet } from "react-helmet"

function Magicrop(props) {
    

    return <div className="magicrop-body">
        <Helmet>
            <meta charSet="utf-8" />
            <title>Video Crop editor alternative to Adobe Premier Pro</title>
            <link rel="canonical" href="https://fabio.santo.dev/magicrop-dynamic-cropping-video-editor-mac-software" />
            </Helmet>
            <MCPageHeader>
                <div className="container p-10">
                    <div className="font-bold text-4xl">How To Crop Video Frame Ratio on Adobe Premier Pro</div>
                    <p>One of the amazing feature Premier Pro offers is to crop videos frame size.</p>
                    <p>Unfortunately is not that simple on Premier Pro as it requires some professional skills.</p>
                    <p>Ideally what Premier Pro allow you to do is to crop each imported scene to a fixed frame format.</p>
                    <p>For instance, the scene where a surfer is talking still in front of the camera can easily be cropped selecting the area where the subject is visible.</p>
                    <p>In a following shot a young kid is running on the beach, his moving across the video because his playing with his friend. In this case on Adobe Premier Pro you have to select which area of the frame makes more sense to crop and discard the rest.</p>
                    <p>Adobe Premier Pro doesn't have at this time a simple feature to move and select a different cropped area within the same scenes.</p>
                    <br></br>
                    <p>Magicrop is a Mac and iPad app wich helps you crop your video in many format size.</p>
                    <p>1:1 for Instagram posts, 16:9 for generic full screen mobile screen size are just some of the many format you can export your video to.</p>
                    <p>Additionally with Magicrop it's easy to track subjects in motion like a surfer riding a wave. Instead of cutting out the subject out of the frame, simply drag and drop the red frame following your subject.</p>
                    <p>Magicrop will crop your video following the movement of your subject.</p>
                    <p>You can download Magicrop for FREE from the iPad App Store or from the Mac App Store</p>
                </div>

            </MCPageHeader>
        </div>
}

export default Magicrop